import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { PROMOTIONS_API } from 'configs/api/promotions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { useHttpClient } from 'hooks';
import { EPromotionRecommendationStatus, EPromotionType, IPromotionListResponse } from 'types/api';
import { IListResponse, TEmptyFunction } from 'types/common';
import RecommendationCard from '../SharedComponents/Recommendations/RecommendationCard';
import RecommendedCampaignDrawer from '../SharedComponents/Recommendations/RecommendedCampaignDrawer';
import styles from './RecommendedSection.module.css';
import { App, Button, Flex, Typography } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, LikeOutlined } from '@ant-design/icons';

const RECOMMENDATIONS_PER_SLIDE = 3;
const CAROUSEL_ITEM_ID = 'carousel-item';

interface IRecommendedSectionProps {
	refreshingData: TEmptyFunction;
}

export const RecommendedSection: FC<IRecommendedSectionProps> = ({ refreshingData }) => {
	const navigate = useNavigate();
	const { message } = App.useApp();
	const { vendorId } = useParams();
	const { t: tCampaigns } = useTranslation('campaigns');

	// ! refs
	const carouselRef = useRef<HTMLDivElement>(null);

	// ! states
	const [recommendedCampaigns, setRecommendedCampaigns] = useState<IPromotionListResponse[]>([]);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [selectedCampaign, setSelectedCampaign] = useState<IPromotionListResponse>();

	const [disablePrevBtn, setDisablePrevBtn] = useState(true);
	const [disableNextBtn, setDisableNextBtn] = useState(false);

	// ! memos
	const showCarouselButtons: boolean = useMemo(
		() => recommendedCampaigns.length > RECOMMENDATIONS_PER_SLIDE,
		[recommendedCampaigns]
	);

	// ! http clients
	const fetchRecommendationsClient = useHttpClient<IListResponse<IPromotionListResponse>>();
	const launchHttpClient = useHttpClient();

	// ! handlers
	const nextSlide = () => {
		if (!carouselRef?.current) return null;
		carouselRef.current.scrollLeft += carouselRef.current.clientWidth;
	};

	const prevSlide = () => {
		if (!carouselRef?.current) return null;
		carouselRef.current.scrollLeft -= carouselRef.current.clientWidth;
	};

	// * Carrousel arrows
	const carouselFirstItemObserverCallback = (entries: IntersectionObserverEntry[]) => {
		if (entries[0].isIntersecting) {
			setDisablePrevBtn(true);
			setDisableNextBtn(false);
		} else {
			setDisablePrevBtn(false);
		}
	};

	const carouselLastItemObserverCallback = (entries: IntersectionObserverEntry[]) => {
		if (entries[0].isIntersecting) {
			setDisablePrevBtn(false);
			setDisableNextBtn(true);
		} else {
			setDisableNextBtn(false);
		}
	};

	const handleCampaignPreviewOpen = (campaign: IPromotionListResponse) => {
		setOpenDrawer(true);
		setSelectedCampaign(campaign);
	};

	const handleCampaignPreviewClose = () => {
		setOpenDrawer(false);
	};

	const handleLaunchCampaign = (record: IPromotionListResponse) => {
		if (!vendorId) return;

		launchHttpClient.request({
			requestConfig: PROMOTIONS_API.launch(+vendorId, record.id),
			successCallback: () => {
				handleCampaignPreviewClose();
				refreshingData();
				message.success(tCampaigns('actions.launch.success'), 5);
			},
		});
	};

	// ! effects
	// * fetch recommendation campaigns
	useEffect(() => {
		if (!vendorId) return;

		fetchRecommendationsClient.request({
			requestConfig: PROMOTIONS_API.list(+vendorId, EPromotionType.RECOMMENDATION),
			successCallback: (response) => {
				const validRecommendationCampaigns = response.data.filter(
					(recommendation) =>
						recommendation.configurations.recommendation_status === EPromotionRecommendationStatus.ACTIVE
				);
				setRecommendedCampaigns(validRecommendationCampaigns);
			},
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorId]);

	// * init carousel observers
	useEffect(() => {
		if (!carouselRef.current || recommendedCampaigns.length === 0) return;

		const carouselItems = [...carouselRef.current.querySelectorAll(`#${CAROUSEL_ITEM_ID}`)];

		const observerOptions: IntersectionObserverInit = {
			root: carouselRef.current,
			rootMargin: '0px',
			threshold: 1,
		};

		const firstElObserver = new IntersectionObserver(carouselFirstItemObserverCallback, observerOptions);
		const lastElObserver = new IntersectionObserver(carouselLastItemObserverCallback, observerOptions);

		// Observe each carousel item
		carouselItems.forEach((item, index) => {
			if (index === 0) {
				firstElObserver.observe(item);
				return;
			}

			if (index === recommendedCampaigns.length - 1) {
				lastElObserver.observe(item);
				return;
			}
		});

		return () => {
			if (firstElObserver) firstElObserver.disconnect();
			if (lastElObserver) lastElObserver.disconnect();
		};
	}, [carouselRef, recommendedCampaigns]);

	// ! render
	if (!recommendedCampaigns?.length) return null;

	return (
		<Flex
			vertical
			gap={16}
		>
			<Flex
				align='center'
				gap={10}
			>
				<LikeOutlined style={{ fontSize: '20px' }} />

				<Typography.Title
					level={5}
					style={{ marginBlock: 0 }}
				>
					{tCampaigns('recommendations.title').toUpperCase()}
				</Typography.Title>
			</Flex>

			<Flex
				align='center'
				justify='space-between'
				className={styles.carousel_wrapper}
			>
				<div
					ref={carouselRef}
					className={styles.carousel}
				>
					{showCarouselButtons && (
						<Button
							shape='circle'
							className={`${styles.carousel_button} ${styles.prev_button}`}
							onClick={prevSlide}
							icon={<ArrowLeftOutlined />}
							disabled={disablePrevBtn}
						/>
					)}
					{recommendedCampaigns.map((campaign, index) => (
						<div
							className={styles.item}
							key={index}
							id={CAROUSEL_ITEM_ID}
						>
							<RecommendationCard
								campaign={campaign}
								call_to_action_text={tCampaigns('recommendations.card.action')}
								callToActionCallback={() => handleCampaignPreviewOpen(campaign)}
							/>
						</div>
					))}
					{showCarouselButtons && (
						<Button
							shape='circle'
							className={`${styles.carousel_button} ${styles.next_button}`}
							onClick={nextSlide}
							icon={<ArrowRightOutlined />}
							disabled={disableNextBtn}
						/>
					)}
				</div>
			</Flex>

			<RecommendedCampaignDrawer
				open={openDrawer}
				campaignData={selectedCampaign!}
				handleOnClose={handleCampaignPreviewClose}
				onEdit={(record) => {
					const to = {
						pathname: generatePath(ABSOLUTE_ROUTES.CAMPAIGN_CREATE, { vendorId }),
						search: new URLSearchParams({ promotionId: record.id.toString() }).toString(),
					};

					navigate(to);
				}}
				handleLaunchCampaign={handleLaunchCampaign}
				isLaunching={launchHttpClient.isLoading}
			/>
		</Flex>
	);
};
