import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_TIME_INTL_FORMAT } from 'utils/date';
import { ECampaignFormFields } from 'types/api/promotions';
import { IResumeValueProps } from './types';
import { CustomCard } from 'pages/Campaigns/SharedComponents/CustomCard';
import { formatDateCampaignDate } from 'pages/Campaigns/SharedComponents/TimeRangeSlider/TimeRangeSlider.configs';
import { useCampaignCreateContext } from 'pages/Campaigns/hooks/useCampaignCreateController';
import styles from './CreateCampaignFooter.module.css';
import dayjs from 'dayjs';
import { Button, Col, Row, Space, Typography } from 'antd';
import { RocketOutlined } from '@ant-design/icons';

const CreateCampaignFooter: FC = () => {
	const { t: tCampaigns } = useTranslation('campaigns');

	const controller = useCampaignCreateContext();

	// ! memos
	const resumeValues: Array<IResumeValueProps> = useMemo(
		() => {
			const resumeValues = [];
			const start_date = controller.formData.start_date;
			if (start_date) {
				resumeValues.push({
					fieldName: tCampaigns('create.form.start_date.sub.title'),
					value: start_date.format(DATE_TIME_INTL_FORMAT),
				});
			}

			const end_date = controller.formData.end_date;
			if (end_date) {
				resumeValues.push({
					fieldName: tCampaigns(`create.form.end_date.sub.title`),
					value: end_date.format(DATE_TIME_INTL_FORMAT),
				});
			}

			resumeValues.push(
				{
					fieldName: tCampaigns(`create.form.time_range.sub.title`),
					value:
						controller.formData.time_range &&
						formatDateCampaignDate(dayjs.duration(controller.formData.time_range, 'seconds')),
				},
				{
					fieldName: tCampaigns(`create.form.${ECampaignFormFields.TARGET}.label`),
					value: tCampaigns(`enums.target.${controller.formData.target}`),
				}
			);

			return resumeValues;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			controller.formData.end_date,
			controller.formData.start_date,
			controller.formData.target,
			controller.formData.time_range,
		]
	);

	// ! render
	return (
		<CustomCard className={styles.card_footer}>
			<Row
				gutter={[16, 16]}
				justify={'end'}
			>
				<Col span={24}>
					<Typography.Title
						level={4}
						style={{
							margin: 0,
							color: 'white',
							textTransform: 'uppercase',
						}}
					>
						{tCampaigns('create.form.resume.title')}
					</Typography.Title>
				</Col>
				<Col flex='auto'>
					<Space
						size={32}
						split={<div className={styles.split} />}
					>
						{resumeValues.map((values, index) => (
							<Space
								direction='vertical'
								key={index}
								className={styles.spacing}
							>
								<h2 className={styles.sub_title}>{values.fieldName}</h2>
								<div className='w-100'>{values.value}</div>
							</Space>
						))}
					</Space>
				</Col>
				<Col flex='none'>
					<Button
						size='large'
						type='primary'
						htmlType='submit'
						className={styles.launch_button}
						icon={
							<RocketOutlined
								size={20}
								rotate={45}
							/>
						}
						onClick={() => controller.handleCreateCampaign(true)}
					>
						{tCampaigns('action_buttons.launch_campaign')}
					</Button>
				</Col>
			</Row>
		</CustomCard>
	);
};

export default CreateCampaignFooter;
