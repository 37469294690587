import { FC, PropsWithChildren, ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PROMOTION_DISCOUNT_TYPE_SHOWS_MAX_DISCOUNT_AMOUNT_FILED, USAGE_TYPE_ICONS } from './configs';
import { useAuthenticatedInformation, useCountry } from 'hooks';
import {
	ECampaignFormFields,
	EPromotionDiscountType,
	EPromotionTarget,
	EPromotionUsageType,
} from 'types/api/promotions';
import TranslatedLabel from 'components/TranslatedLabel';
import { useCampaignCreateContext } from '../../hooks/useCampaignCreateController';
import PercentageInput from '../PercentageInput';
import { DiscountAdvancedOptionsDrawer } from './AdvancedDrawer/DiscountAdvancedOptionsDrawer';
import { CustomTabs } from './CustomTabs';
import styles from './DiscountValueInput.module.css';
import { useGenerateCodeController } from './useGenerateCodeController';
import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Space, TabsProps, Tooltip, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { NamePath } from 'antd/es/form/interface';
import { ThunderboltOutlined } from '@ant-design/icons';

interface IUsageTypeWrapperProps extends PropsWithChildren {
	usageType?: EPromotionUsageType;
	tabItems: TabsProps['items'];
	disabled: boolean;
}

const UsageTypeWrapper: FC<IUsageTypeWrapperProps> = ({ usageType, tabItems, disabled, children }) => {
	const form = useFormInstance();
	// ! render
	return (
		<Card
			className={styles.radio_option_card}
			style={{ borderRadius: 16, paddingTop: 0 }}
			styles={{ header: { padding: '12px 0 0 0' } }}
			title={
				<CustomTabs
					items={tabItems}
					activeKey={usageType}
					onTabClick={(newUsageType) => {
						if (!disabled) {
							form.setFieldValue(ECampaignFormFields.USAGE_TYPE, newUsageType);
						}
					}}
				/>
			}
		>
			{children}
		</Card>
	);
};

export const DiscountValueInput: FC = () => {
	const form = useFormInstance();

	const { t: tCampaigns } = useTranslation('campaigns');

	const {
		formData: { discount_type, usage_type, target },
		isPromotionCode,
		isFreeDelivery,
	} = useCampaignCreateContext();

	// Cant get vendor id from controller
	const vendorId = form.getFieldValue(ECampaignFormFields.VENDOR_ID);

	const generateCodeController = useGenerateCodeController();
	const { user } = useAuthenticatedInformation();
	const { selectedCountryCurrencyCode } = useCountry();

	const isAmountPromotion =
		discount_type === EPromotionDiscountType.AMOUNT || discount_type === EPromotionDiscountType.CASHBACK_AMOUNT;
	const isPercentagePromotion =
		discount_type === EPromotionDiscountType.PERCENTAGE ||
		discount_type === EPromotionDiscountType.CASHBACK_PERCENTAGE;

	const showMaximumDiscountAmount = PROMOTION_DISCOUNT_TYPE_SHOWS_MAX_DISCOUNT_AMOUNT_FILED[discount_type!];

	interface ITabLabelProps {
		icon: ReactNode;
		title: ReactNode;
		description: ReactNode;
	}

	const TabLabel: FC<ITabLabelProps> = ({ icon, description, title }) => {
		return (
			<div className={styles.tabs_label_container}>
				<div className={`${styles.tabs_label_title} h5`}>
					{icon}
					{title}
				</div>

				<Typography.Text type='secondary'>
					<span className={styles.tabs_label_description}>{description}</span>
				</Typography.Text>
			</div>
		);
	};

	const promotion_usable_types: EPromotionUsageType[] = useMemo(() => {
		if (isPromotionCode) {
			return [EPromotionUsageType.MANUAL];
		} else {
			if (isFreeDelivery) {
				return [EPromotionUsageType.AUTOMATIC];
			}
		}

		return Object.values(EPromotionUsageType);
	}, [isFreeDelivery, isPromotionCode]);

	const tabItems: TabsProps['items'] = useMemo(
		() =>
			promotion_usable_types.map((usageType) => ({
				key: usageType,
				label: (
					<TabLabel
						icon={USAGE_TYPE_ICONS[usageType]}
						title={
							<TranslatedLabel
								nameSpace='campaigns'
								i18nKey={`create.form.usage_type.${usageType}.label`}
							/>
						}
						description={
							<TranslatedLabel
								nameSpace='campaigns'
								i18nKey={`create.form.usage_type.${usageType}.description`}
							/>
						}
					/>
				),
			})),
		[promotion_usable_types]
	);

	const possibleBranchListOptions = useMemo(
		() =>
			user.stores
				.filter((store) => store.vendor_id === +vendorId)
				.map((store) => ({
					value: store.id,
					label: `(#${store.id}) ${store.name}`,
				})),
		[user.stores, vendorId]
	);

	const branchSelectorDropdownRender = useCallback(
		(menu: ReactElement) => {
			const stores_ids = form.getFieldValue([
				ECampaignFormFields.MULTI_VENDOR,
				0,
				ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST,
			]);

			const isAllSelected = !stores_ids?.length;
			const storesIdsSelect: NamePath = [
				ECampaignFormFields.MULTI_VENDOR,
				0,
				ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST,
			];

			return (
				<>
					<div
						onClick={() => {
							form.setFieldValue(storesIdsSelect, []);
						}}
						aria-selected={isAllSelected}
						className={`ant-select-item ant-select-item-option ${
							isAllSelected ? 'ant-select-item-option-active ant-select-item-option-selected' : ''
						}`}
					>
						<div className='ant-select-item-option-content'>
							{tCampaigns('create.form.discount_advanced_settings.store_target.all')}
						</div>
						{isAllSelected && (
							<span
								className='ant-select-item-option-state'
								unselectable='on'
								aria-hidden='true'
							>
								<span
									role='img'
									aria-label='check'
									className='anticon anticon-check'
								>
									<svg
										viewBox='64 64 896 896'
										focusable='false'
										data-icon='check'
										width='1em'
										height='1em'
										fill='currentColor'
										aria-hidden='true'
									>
										<path d='M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z'></path>
									</svg>
								</span>
							</span>
						)}
					</div>
					{menu}
				</>
			);
		},
		[form, tCampaigns]
	);

	return (
		<>
			<Form.Item
				name={ECampaignFormFields.USAGE_TYPE}
				hidden
			>
				<Input />
			</Form.Item>

			<UsageTypeWrapper
				tabItems={tabItems}
				usageType={usage_type}
				disabled={false}
			>
				<Row
					justify='end'
					gutter={[24, 24]}
				>
					{usage_type === EPromotionUsageType.MANUAL && (
						<Col span={24}>
							<FormItem
								colon={false}
								wrapperCol={{ style: { display: 'flex', flexDirection: 'column-reverse' } }}
								name={ECampaignFormFields.CODE}
								label={tCampaigns(`create.form.${ECampaignFormFields.CODE}.label`)}
								extra={tCampaigns(`create.form.${ECampaignFormFields.CODE}.description`)}
								rules={[{ max: 32, required: true, whitespace: true }]}
							>
								<Space.Compact
									size='large'
									className='w-100'
								>
									<Form.Item
										name={ECampaignFormFields.CODE}
										noStyle
									>
										<Input
											style={{
												width: 'calc(100% - 100px)',
											}}
											max={32}
											size='large'
											onInput={(e) => {
												const target = e.target as HTMLInputElement;

												target.value = target.value.toUpperCase();
											}}
											placeholder={tCampaigns(
												`create.form.${ECampaignFormFields.CODE}.placeholder`
											)}
										/>
									</Form.Item>
									<Tooltip
										title={tCampaigns(`create.form.${ECampaignFormFields.CODE}.generate.tooltip`)}
									>
										<Button
											size='large'
											onClick={() => generateCodeController.handleGenerateCode()}
											loading={generateCodeController.loading}
											icon={<ThunderboltOutlined />}
										>
											{tCampaigns(`create.form.${ECampaignFormFields.CODE}.generate.cta`)}
										</Button>
									</Tooltip>
								</Space.Compact>
							</FormItem>
						</Col>
					)}
					{isAmountPromotion && (
						<Col flex='auto'>
							<FormItem
								name={ECampaignFormFields.DISCOUNT_AMOUNT}
								label={tCampaigns(`create.form.${ECampaignFormFields.DISCOUNT_AMOUNT}.label`)}
								rules={[{ required: true }]}
							>
								<InputNumber
									size='large'
									min={0}
									addonAfter={selectedCountryCurrencyCode}
								/>
							</FormItem>
						</Col>
					)}
					{isPercentagePromotion && (
						<Col flex='auto'>
							<div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
								<PercentageInput />
							</div>
						</Col>
					)}

					<Col
						flex='none'
						style={{ alignSelf: 'flex-end' }}
					>
						<DiscountAdvancedOptionsDrawer>
							<Row gutter={[24, 24]}>
								{vendorId && (
									<Col span={24}>
										<FormItem
											name={[
												ECampaignFormFields.MULTI_VENDOR,
												0,
												ECampaignFormFields.MULTI_VENDOR_BRANCH_LIST,
											]}
											label={tCampaigns(
												'create.form.discount_advanced_settings.store_target.label'
											)}
											extra={tCampaigns(
												'create.form.discount_advanced_settings.store_target.description'
											)}
											labelCol={{ span: 24 }}
											wrapperCol={{ style: { display: 'flex', flexDirection: 'column-reverse' } }}
										>
											<Select
												mode='multiple'
												maxTagCount={2}
												placeholder={tCampaigns(
													'create.form.discount_advanced_settings.store_target.placeholder'
												)}
												size='large'
												dropdownRender={branchSelectorDropdownRender}
												options={possibleBranchListOptions}
												disabled={possibleBranchListOptions.length === 0}
											/>
										</FormItem>
									</Col>
								)}
								<Col span={24}>
									<FormItem
										name={ECampaignFormFields.MINIMUM_ORDER_AMOUNT}
										label={tCampaigns(
											`create.form.discount_advanced_settings.${ECampaignFormFields.MINIMUM_ORDER_AMOUNT}.label`
										)}
										extra={tCampaigns(
											`create.form.discount_advanced_settings.${ECampaignFormFields.MINIMUM_ORDER_AMOUNT}.description`
										)}
										labelCol={{ span: 24 }}
										wrapperCol={{ style: { display: 'flex', flexDirection: 'column-reverse' } }}
									>
										<InputNumber
											style={{ width: '100%' }}
											size='large'
											addonAfter={selectedCountryCurrencyCode}
											min={0}
										/>
									</FormItem>
								</Col>
								{showMaximumDiscountAmount && (
									<Col span={24}>
										<FormItem
											name={ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT}
											label={tCampaigns(
												`create.form.discount_advanced_settings.${ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT}.label`
											)}
											extra={tCampaigns(
												`create.form.discount_advanced_settings.${ECampaignFormFields.MAXIMUM_DISCOUNT_AMOUNT}.description`
											)}
											wrapperCol={{ style: { display: 'flex', flexDirection: 'column-reverse' } }}
										>
											<InputNumber
												style={{ width: '100%' }}
												size='large'
												addonAfter={selectedCountryCurrencyCode}
												min={0}
											/>
										</FormItem>
									</Col>
								)}
								<Col span={24}>
									<FormItem
										name={ECampaignFormFields.USER_USAGE_LIMIT}
										label={tCampaigns(
											`create.form.discount_advanced_settings.${ECampaignFormFields.USER_USAGE_LIMIT}.label`
										)}
										extra={tCampaigns(
											`create.form.discount_advanced_settings.${ECampaignFormFields.USER_USAGE_LIMIT}.description`
										)}
										labelCol={{ span: 24 }}
										wrapperCol={{ style: { display: 'flex', flexDirection: 'column-reverse' } }}
									>
										<InputNumber
											size='large'
											disabled={target === EPromotionTarget.ACQUISITION}
											className='w-100'
											min={0}
										/>
									</FormItem>
								</Col>
								<Col span={24}>
									<FormItem
										name={ECampaignFormFields.GLOBAL_USAGE_LIMIT}
										label={tCampaigns(
											`create.form.discount_advanced_settings.${ECampaignFormFields.GLOBAL_USAGE_LIMIT}.label`
										)}
										extra={tCampaigns(
											`create.form.discount_advanced_settings.${ECampaignFormFields.GLOBAL_USAGE_LIMIT}.description`
										)}
										wrapperCol={{ style: { display: 'flex', flexDirection: 'column-reverse' } }}
									>
										<InputNumber
											size='large'
											className='w-100'
											min={0}
										/>
									</FormItem>
								</Col>
							</Row>
						</DiscountAdvancedOptionsDrawer>
					</Col>
				</Row>
			</UsageTypeWrapper>
		</>
	);
};

export default DiscountValueInput;
