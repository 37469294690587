import { PROMOTIONS_API } from 'configs/api/promotions';
import { useHttpClient } from 'hooks';
import { ECampaignFormFields, IPromotionGenerateCodeResponse } from 'types/api/promotions';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';

interface IGenerateCodeController {
	loading: boolean;
	handleGenerateCode: () => Promise<void>;
}

export const useGenerateCodeController = (): IGenerateCodeController => {
	const form = useFormInstance();

	// ! http client
	const generateCodeHttpClient = useHttpClient<IPromotionGenerateCodeResponse>();

	const handleGenerateCode = () =>
		generateCodeHttpClient.request({
			requestConfig: PROMOTIONS_API.generatePromoCode(+form.getFieldValue('vendor_id')),
			successCallback: (response) => {
				form.setFieldValue(ECampaignFormFields.CODE, response.code);
			},
		});

	return { loading: generateCodeHttpClient.isLoading, handleGenerateCode };
};
