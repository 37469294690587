import { FC, memo } from 'react';
import { Property } from 'csstype';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { EPromotionTarget } from 'types/api/promotions';
import { TranslatedLabel } from 'components/TranslatedLabel/TranslatedLabel';
import { Tag } from 'antd';

interface IPromotionTargetTagProps {
	type: EPromotionTarget;
}

const COLOR_CONFIG: Record<EPromotionTarget, Property.Color> = {
	[EPromotionTarget.DEFAULT]: 'red',
	[EPromotionTarget.RETENTION]: 'green',
	[EPromotionTarget.ACQUISITION]: 'blue',
};

export const PromotionTargetTag: FC<IPromotionTargetTagProps> = memo(({ type }) => {
	// ! render

	return (
		<Tag
			color={COLOR_CONFIG[type]}
			style={DEFAULT_TAG_STYLES}
		>
			<TranslatedLabel
				nameSpace='campaigns'
				i18nKey={type}
				extra={{ keyPrefix: 'enums.target' }}
			/>
		</Tag>
	);
});
